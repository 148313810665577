import { Component, Inject } from '@angular/core';
import { Icon } from '@wam/icons';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssignmentBatchItemResponse, AssignmentResponse } from '@wap/catalog-v5';
import { StudentAnalytics } from '@wam/shared';

@Component({
  selector: 'goal-save-confirmation-dialog',
  templateUrl: './goal-save-confirmation-dialog.component.html',
  styleUrls: ['./goal-save-confirmation-dialog.component.scss'],
})
export class GoalSaveConfirmationDialogComponent {
  Icon = Icon;

  get failureStudentNames(): string[] {
    const studentNames = this.data.failures.map((assignment) => {
      const student = this.data.students.find(
        (student) => student.uuid === assignment.operationBody?.user,
      );
      return student.fullName;
    });

    return [...new Set(studentNames)];
  }

  get studentNames(): string[] {
    const studentNames = this.data.successes.map((assignment) => {
      const student = this.data.students.find((student) => student.uuid === assignment.user);
      return student.fullName;
    });

    return [...new Set(studentNames)];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      students: StudentAnalytics[];
      failures: AssignmentBatchItemResponse[];
      successes: AssignmentResponse[];
    },
  ) {}
}
