<div class="goal-save-confirmation-dialog">
  <div mat-dialog-title>
    {{ 'Save Confirmation' | translate }}
    <cl-functional-button
      mat-dialog-close
      [icon]="Icon.cancel"
      class="course-assign-license-error-dialog__close"
      size="32px"></cl-functional-button>
  </div>
  <div mat-dialog-content>
    <div class="goal-save-confirmation-dialog__info">
      <ng-container *ngIf="data.failures.length === 0; else withFailures">
        <p class="line">
          {{
            'Success! The selected course has been assigned to all students in the class.'
              | translate
          }}
        </p>
        <p class="line">
          {{
            'Please note that the assignment has been made for all students currently rostered to this class.' +
              ' This will not automatically assign the course to any students if they transfer into this class.'
              | translate
          }}
        </p>
      </ng-container>
      <ng-template #withFailures>
        <p class="line">
          {{
            'Due to limited license availability, not all of your changes were saved.' | translate
          }}
        </p>
        <ng-container *ngIf="data.successes.length > 0">
          <p class="line">
            {{ 'The following students received the course assignment:' | translate }}
          </p>
          <div class="line">
            <div
              *ngFor="let studentName of studentNames"
              class="name">
              {{ studentName }}
            </div>
          </div>
        </ng-container>
        <p class="line">
          {{ 'The following students did NOT receive the course assignment:' | translate }}
        </p>
        <div class="line">
          <div
            *ngFor="let studentName of failureStudentNames"
            class="name">
            {{ studentName }}
          </div>
        </div>
        <p class="line">
          {{
            'Please contact your administrator to ensure licensing needs are met. Courses can be both' +
              ' assigned and unassigned one by one in the students view.' | translate
          }}
        </p>
      </ng-template>
    </div>
  </div>
  <div mat-dialog-actions>
    <cl-button mat-dialog-close>{{ 'OK' | translate }}</cl-button>
  </div>
</div>
